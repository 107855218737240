
import { useCallback, useEffect, useState } from 'react';
import { Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import { userForgetPassword } from '../../api/forgetPasswordApi';

export const useForgetPassword = props => {

    const navigate = useNavigate();

    const [form] = Form.useForm();

    const [clientReady, setClientReady] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        setClientReady(true);
    }, []);

    const onStartLogin = useCallback((values) => {
        setLoginLoading(true);
        userForgetPassword(values).then(res => {
            console.log('res', res);
            setLoginLoading(false);
            if (res && res.response.isSuccess) {
                navigate('/resetpassword', { replace: true })
            }
            else {
                setShowErrorMsg(true);
                setErrorMsg(res.response.errorMessage);
            }
        }).catch(error => {
            console.error(error)
            setLoginLoading(false);
        })

    }, [navigate, form]);

    useEffect(() => {
        const userLocalData = localStorage.getItem("userLocalData");
        if (userLocalData) {
            const userSaveData = JSON.parse(userLocalData);
            if (userSaveData && userSaveData.remember) {
                form.setFieldsValue(userSaveData)
            }
        }
    }, []);

    const handleNavigateBack = useCallback(() => {
        navigate('/login', { replace: false })
    })


    return {
        form,
        clientReady,
        loginLoading,
        onStartLogin,
        errorMsg,
        showErrorMsg,
        setShowErrorMsg,
        handleNavigateBack
    };

}