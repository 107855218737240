import React, { Fragment } from 'react';
import defaultClasses from './textInput.module.css';
import { Form, Input, InputNumber } from 'antd';
import {useStyle} from '../../../../utils/classify';

const TextInput = props=> {
    const {notFormItem, formProps, styleType, label, name, rules, prefix, suffix, placeholder, inputType, ...inputProps} = props;

    const classes = useStyle(defaultClasses, props.classes);
    
    return notFormItem ? (
        <div className={`${classes.root} ${classes.other_root}`}>
            {
                inputType === 'password' ? <Input.Password
                    prefix={prefix} 
                    suffix={suffix}
                    placeholder={placeholder} 
                    {...inputProps}
                /> : inputType === 'search' ? <Input.Search
                    prefix={prefix} 
                    suffix={suffix}
                    placeholder={placeholder} 
                    {...inputProps}
                /> : inputType === 'textarea' ? <Input.TextArea
                    prefix={prefix} 
                    suffix={suffix}
                    placeholder={placeholder} 
                    {...inputProps}
                /> : inputType === 'numberInput' ? <InputNumber
                    prefix={prefix} 
                    suffix={suffix}
                    placeholder={placeholder} 
                    {...inputProps}
                /> : <Input
                    prefix={prefix} 
                    suffix={suffix}
                    placeholder={placeholder} 
                    {...inputProps}
                />
            }
        </div>
    ) : (
        <Form.Item
            className={`${classes.root} ${styleType == 'wrap' ? classes.wrap_root : ''} ${classes.other_root}`}
            label={label}
            name={name}
            rules={rules}
            {...formProps}
        >
            {
                inputType === 'password' ? <Input.Password
                    prefix={prefix} 
                    suffix={suffix}
                    placeholder={placeholder} 
                    {...inputProps}
                /> : inputType === 'textarea' ? <Input.TextArea
                    prefix={prefix} 
                    suffix={suffix}
                    placeholder={placeholder} 
                    {...inputProps}
                /> : inputType === 'numberInput' ? <InputNumber
                    prefix={prefix} 
                    suffix={suffix}
                    placeholder={placeholder} 
                    {...inputProps}
                /> : <Input
                    prefix={prefix} 
                    suffix={suffix}
                    placeholder={placeholder} 
                    {...inputProps}
                />
            }
            
        </Form.Item>
    )
}

export default TextInput;