
import React, { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import Login from '../pages/Login/login';
import PasswordForgot from '../pages/PasswordForgot/passwordForgot';
import PasswordReset from '../pages/PasswordReset/passwordReset';
import PasswordResetSuccess from '../pages/PasswordResetSuccess/passwordResetSuccess';
import Homepage from '../pages/Homepage/homePage';

const Dashboard = React.lazy(() => import('../components/DashboardPage/dashboard'));
const Settings = React.lazy(() => import('../components/Settings/settings'));
const MerchantUsers = React.lazy(() => import('../components/MerchantUsers/merchantUsers'));
const MerchantVendor = React.lazy(() => import('../components/MerchantVendor/merchantVendor'));
const Marketplaces = React.lazy(() => import('../components/Marketplaces/marketplaces'));

const Reports = React.lazy(() => import('../components/ReportPage/report'));
const PageNotFound = React.lazy(() => import('../components/common/PageNotFound/pageNotFound'));



// 实现懒加载的用Suspense包裹 定义函数
const lazyLoad = (children) => {
  return <Suspense fallback={<h1>...</h1>}>
    {children}
  </Suspense>
}

const authorRouter = [

  {
    path: '/',
    element: <Homepage />,
    auth: true,
    children: [
      {
        path: '/',
        element: <Navigate to="/index/dashboard" />,
        auth: false
      },
      {
        path: '/index/dashboard',
        messageId: 'router.dashboard',
        element: lazyLoad(<Dashboard />),
      },
      {
        path: '/index/settings',
        messageId: 'router.settings',
        element: lazyLoad(<Settings />),
      },
      {
        path: '/index/merchant-users',
        messageId: 'router.merchantUsers',
        element: lazyLoad(<MerchantUsers />),
      },
      {
        path: '/index/merchant-vendor',
        messageId: 'router.merchantVendor',
        element: lazyLoad(<MerchantVendor />),
      },
      {
        path: '/index/marketplaces',
        messageId: 'router.marketplaces',
        element: lazyLoad(<Marketplaces />),
      },
      {
        path: '/index/reports',
        messageId: 'router.reports',
        element: lazyLoad(<Reports />),
      }
    ]
  },

  {
    path: '/index',
    element: <Navigate to="/index/dashboard" />,
    auth: false
  },
  {
    path: '/login',
    messageId: 'router.login',
    element: <Login />,
  },
  {
    path: '/forgetpassword',
    messageId: 'router.forgetpassword',
    element: <PasswordForgot />,
  },
  {
    path: '/resetpassword',
    messageId: 'router.resetpassword',
    element: <PasswordReset />,
  },
  {
    path: '/resetpassword/success',
    messageId: 'router.passwordResetSuccess',
    element: <PasswordResetSuccess />,
  }
]

export { authorRouter }