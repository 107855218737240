
import { useCallback, useEffect, useState } from 'react';
import { Form } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { userLogin, getProfile } from '../../api/loginApi';
import { useSelector, useDispatch } from 'react-redux';
import { setToken, setIsLoginCompleted, setUserInfo } from '../../redux/userSlice'

export const useLogin = props => {

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const dispatch = useDispatch()

    const [clientReady, setClientReady] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        // localStorage.removeItem("LOGIN_TOKEN");
        setClientReady(true);
    }, []);

    const onStartLogin = useCallback((values) => {
        setLoginLoading(true);
        userLogin(values).then(res => {
            setLoginLoading(false);
            if (res && res.response.isSuccess) {
                localStorage.setItem("userLocalData", JSON.stringify(values));
                localStorage.setItem("LOGIN_TOKEN", res.token);
                // dispatch(setToken(res.token))
                window.location.href = res.merchant.merchantFrontendUrl && process.env.NODE_ENV == "production" ? `${res.merchant.merchantFrontendUrl}/?token=${res.token}` : `${window.location.origin}/index/dashboard?token=${res.token}`;

            } else {
                setShowErrorMsg(true);
                setErrorMsg(res.response.errorMessage);
            }
        }).catch(error => {
            console.error(error)
            setLoginLoading(false);
        })

    }, [navigate, form]);

    useEffect(() => {
        const userLocalData = localStorage.getItem("userLocalData");
        if (userLocalData) {
            const userSaveData = JSON.parse(userLocalData);
            if (userSaveData && userSaveData.remember) {
                form.setFieldsValue(userSaveData)
            }
        }
    }, []);


    const handleForgetPassWord = useCallback(() => {
        navigate('/forgetpassword', { replace: false })
    })


    return {
        form,
        clientReady,
        loginLoading,
        onStartLogin,
        errorMsg,
        showErrorMsg,
        setShowErrorMsg,
        handleForgetPassWord
    };

}