import { get, post, put, deleted } from '../utils/http';

//获取Menu详情
export function getMenuList(param) {
    return get('admin_merchant/v1/menu/list', param);
};

//获取subscribed services详情
export function getMerchantSubscribedServiceList(param) {
    return post('admin_merchant/v1/merchant_subscribed_services/list', param);
};

//获取current merchant permissions
export function getMerchantPermissions(param) {
    return get('admin_merchant/v1/merchant/permissions', param);
};

