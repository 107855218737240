import React from 'react';
import defaultClasses from './pageNotFound.module.css';
import { FormattedMessage, useIntl } from 'react-intl';

const PageNotFound = props=> {


    return (
        <div className={defaultClasses.root}>
            <FormattedMessage id='pageNotFound.pageNotFound' defaultMessage={'Page Not Found'} />
        </div>
    )
}

export default PageNotFound;
