
const zh_HK = {
    "global.webName": "e-com365.com",
    "global.searchBtn": "查詢",
    "global.addBtn": "新增",
    "global.backBtn": "返回",
    "global.male": "男",
    "global.female": "女",
    "global.apiError": "未知錯誤，請重試",
    "global.apiEditSuccess": "修改成功!",
    "global.apiaddSuccess": "創建成功!",
    "global.apidelSuccess": "刪除成功!",
    "global.apisendSuccess": "發送成功!",
    "global.confirmDelModalTitle": "Delete Items",

    "form.isRequired": "{name}是必填項!",
    "form.isValid": "請輸入有效的{name}",
    "form.select": "請選擇",
    "form.cancel": "取消",
    "form.ok": "保存",
    "form.delete": "Delete",
    "form.okBtn": "OK",

    "Login.panelName": "登錄",
    "Login.required_userName": "請輸入用戶名",
    "Login.input_username": "用戶名",
    "Login.required_password": "請輸入密碼",
    "Login.input_password": "密碼",
    "Login.loginBtn": "登錄",
    "Login.panelLeftTitle": "Welcome back to E-Com365 Go CRM Micro-service",
    "Login.panelLeftSpan": "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed qu",
    "Login.panelRightSpan": "Don’t have an account?",
    "Login.panelRightSpanButton": "Register a new account",
    "Login.panelRightBottom": "Copyright @ 2024 BCNetcom. All Rights Reserved",
    "Login.rememberMeLabel": "記住密碼",
    "Login.forgetBtn": "忘記密碼？",
    
    "DashboardHeader.labelTips": "Let’s get creative with E-Com365 Go CRM Micro-service to organize your content.",

    "router.dashboard": "Dashboard",
    "router.customer": "Customer",
    "router.customerSegment": "Customer Segment",
    "router.customerAttribute": "Customer Attribute",
    "router.campaigns": "Campaigns",
    "router.campaignSettings": "Campaign Settings",
    "router.whatsappTemplate": "Whatsapp Template",
    "router.reports": "Reports",
    "router.orders": "Orders",
    "router.promotionSettings": "Promotion Settings",
    "router.login": "Login",
    "router.forgetpassword": "Forget Password",
    "router.resetpassword": "Reset Password",
    "router.passwordResetSuccess": "Reset Password Success",
    "router.PageBuilder":"Page Builder",

    "homeMenu.dashboard": "首頁",
    "homeMenu.customer": "用戶管理",
    "homeMenu.allCustomers": "All Customers",
    "homeMenu.segment": "Segment",
    "homeMenu.customerAttribute": "Attribute",
    "homeMenu.customerSubscribe": "Subscribers",
    "homeMenu.customerSegment": "Customer Segment",
    "router.customerSubscribe": "Customer Subscribers",
    "router.customerGroup": "Customer Group",
    "homeMenu.campaign": "Campaign",
    "homeMenu.campaigns": "Campaigns",
    "homeMenu.campaignSettings": "Settings",
    "homeMenu.whatsappTemplate": "Whatsapp Template",
    "homeMenu.wTemplateCreate": "Create Template",
    "homeMenu.promotion": "Promotion",
    "homeMenu.orders": "Orders",
    "router.ordersConfig": "Order Config",
    "homeMenu.allOrders": "All Orders",
    "homeMenu.reports": "Reports",
    "homeMenu.main": "首頁",
    "homeMenu.push": "消息推送記錄",
    "homeMenu.company": "公司管理",
    "homeMenu.role": "角色管理",
    "homeMenu.user": "管理員",
    "homeUser.loginout": "退出登錄",
    "homeMenu.PageBuilder": "Page Builder",
    "homeMenu.allPageBuilder": "All Pages",
    "homeMenu.reward": 'Reward',

    "Customer.pushBtn": "推送消息",
    "Customer.pushPromoBtn": "推送優惠",
    "Customer.findBtnLabel": "Find",
    "Customer.chooseCompany": "請點擊右邊按鈕選擇公司",
    "Customer.addCustomerTitle": "新增用戶信息",
    "Customer.editCustomerTitle": "編輯用戶信息",
    "Customer.detailCustomerTitle": "Detail Customer",
    "Customer.selectCompanyTitle": "選擇公司",
    "Customer.editPushMessage": "配置推送消息",
    "Customer.pushModal.okBtn": "發送",
    "customer.confirmModalDelMsg": "確認刪除id等於{val}的用戶信息？",
    "customer.selectCompanyWarning": "請選擇公司!",
    "customer.confirmModalPromoMsg": "確認向{val}位用戶推送促銷信息？",
    "customer.addFirstCustomerTitle": "Add First Customer",
    "customer.addFirstCustomerLabel1": "Having a customer list helps to know your audience better.",
    "customer.addFirstCustomerLabel2": "Manage your customers in one place and target your audinece better.",
    "customer.addFirstCustomer": "Add Customer",
    "customer.addFirstCustomerReadMore": "Read More",
    
    "createCustomer.backLabel": "Back",
    
    "detailCustomer.editInfoButton": "Edit information",

    "Attribute.createAttributeTitle": "Create Attribute",
    "Attribute.editAttributeTitle": "Edit Attribute",
    "Attribute.detailAttributeTitle": "Attribute Detail",

    "Segment.addSegmentTitle": "Create Segment",
    "Segment.detailSegmentTitle": "Detail Segment",
    "Segment.editSegmentTitle": "Edit Segment",
    "Segment.confirmModalDelMsg": "Are you sure to delete segment data with id equal {val}?",

    "Subscribe.createTitle": "Create Subscriber",
    "Subscribe.editTitle": "Edit Subscriber",
    
    "campaigns.addNewCampaignLabel": "Create New Campaigns",
    "campaigns.saveButton": "Save Campaign",
    "campaigns.editCampaignLabel": "Edit The Campaign",
    "campaigns.sendCampaignLabel": "Send Campaign",

    "whatsappTemplate.addNewDataLabel": "Create New Template",

    "Order.addOrderTitle": "Create Order",
    "Order.detailOrderTitle": "Information",
    "Order.OrderInvoiceTitle": "Invoice",
    "Order.OrderShipmentTitle": "Shipment",
    "Order.editOrderTitle": "Edit Order",
    "Order.addFirstOrderTitle": "No Orders Yet",
    "Order.addFirstOrderLabel1": "All the upcoming orders from your store will be visible in this page.",
    "Order.addFirstOrderLabel2": "You can add orders by yourself if you sell offline.",
    "Order.addFirstOrder": "Add Order",

    "Config.createTitle": "Create Config",
    "Config.editTitle": "Edit Config",

    "PageBuilder.detailPageTitle": "Detail Page",
    "PageBuilder.addPageTitle": "Create Page",
    
    "exports.exportButton": "Export",
    "exports.customerGrowth": "Customer Growth",
    "exports.salesGoal": "Sales Goal",
    "exports.conversionRate": "Conversion Rate",
    "exports.storeFunnel": "Store Funnel",
    "exports.ageDistribution": "Age Distribution",
    "exports.averageOrderValue": "Average Order Value",

    "company.addCompanyTitle": "新增公司信息",
    "company.editCompanyTitle": "編輯公司信息",
    "company.confirmModalDelMsg": "確認刪除id等於{val}的公司信息？",
    
    "Message.PushDetailTitle": "消息詳情",
    "Message.PushTargetTitle": "消息推送目標",
    "Message.notFoundDetail": "未知的消息",
    "MessageModal.text_isRequired": "請輸入内容或移除本輸入框",

    "pushModal.addTextBtn": "添加新文本",
    "pushModal.addImageBtn": "上傳圖片",
    "pushModal.addImagePlaceHolder": "輸入圖片鏈接或上傳圖片(png/jpg/jpeg), 多條鏈接用[ , ]分隔",
    "pushModal.addWeImagePlaceHolder": "輸入圖片鏈接或上傳圖片(png/jpg), 多條鏈接用[ , ]分隔",
    "pushModal.uploadImageTypeError": "{name}不是有效的圖片類型",
    "pushModal.addVideoBtn": "上傳視頻",
    "pushModal.addVideoPlaceHolder": "輸入視頻鏈接或上傳視頻(mp4/avi/mov/flv), 多條鏈接用[ , ]分隔",
    "pushModal.addWeVideoPlaceHolder": "輸入視頻鏈接或上傳視頻(mp4), 多條鏈接用[ , ]分隔",
    "pushModal.uploadVideoTypeError": "{name}不是有效的視頻類型",
    "pushModal.addDocumentBtn": "上傳文檔",
    "pushModal.addDocumentPlaceHolder": "輸入文檔鏈接或上傳文檔(doc/docx/pdf/xls/xlsx/ppt/pptx), 多條鏈接用[ , ]分隔",
    "pushModal.uploadDocumentTypeError": "{name}不是有效的文檔類型",
    "pushModal.uploadSize": "文件最大不得超過30M",

    "role.addRoleTitle": "Create Role",
    "role.editRoleTitle": "Edit Role",
    "role.confirmModalDelMsg": "Are you sure to delete role data with RoleName equal {val}?",

    "user.addUserTitle": "Create User",
    "user.editUserTitle": "Edit User",
    "user.confirmModalDelMsg": "Are you sure to delete user data with Uid equal {val}?",

}
export {
    zh_HK
}   