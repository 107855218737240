
import React, { useCallback } from 'react';
import defaultClasses from './passwordResetSuccess.module.css';
import loginClasses from '../Login/login.module.css';
import { useStyle } from '../../utils/classify';
import { Form, message } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import LandingWelcome from '../../components/LandingWelcome/landingWelcome';
import { useNavigate } from 'react-router-dom';
import ButtonNode from '../../components/common/formDesign/ButtonNode/buttonNode';
import resetPasswordSuccessIcon from '../../static/resetPasswordSuccess.svg'

const PasswordResetSuccess = props => {

    const { formatMessage } = useIntl();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    const handleNavigateBack = useCallback(() => {
        navigate('/login', { replace: false })
    })

    const classes = useStyle(loginClasses, defaultClasses, props.classes);

    return (
        <div className={classes.root}>
            {contextHolder}
            <aside className={classes.panel}>
                <div className={classes.panel_left}>
                    <LandingWelcome />
                </div>
                <div className={classes.panel_right}>
                    <div className={classes.right_box}>
                        <div className={classes.right_content}>
                            <div className={classes.icon}><img src={resetPasswordSuccessIcon} style={{ with: '64px', height: '64px' }} /></div>
                            <h1 className={classes.title}>
                                <FormattedMessage id="passwordResetSuccess.successful" defaultMessage={"Successful"} />
                            </h1>
                            <div className={classes.right_top_create}>
                                <FormattedMessage id="passwordResetSuccess.panelRightSpan" defaultMessage={"The password has been changed successfully"} />
                            </div>
                            <div className={classes.form_other}>
                                <ButtonNode
                                    type="primary"
                                    onClick={handleNavigateBack}
                                    style={{ width: '100%' }}
                                >
                                    <FormattedMessage id="passwordResetSuccess.navigateBack" defaultMessage={"Back to Sign In"} />
                                </ButtonNode>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </div>
    )
}

export default PasswordResetSuccess;